* {
  user-select: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.size-animation {
  animation: size-anim ease-out 0.2s;
}

@font-face {
  font-family: "digital";
  src: url("assets/DS-DIGI.ttf") format("truetype");
}

@font-face {
  font-family: "digital700";
  src: url("assets/DS-DIGIB.ttf") format("truetype");
}

@font-face {
  font-family: "inter400";
  font-weight: 400;
  src: url("assets/Inter-V.ttf") format("truetype");
}

@font-face {
  font-family: "inter600";
  font-weight: 600;
  src: url("assets/Inter-V.ttf") format("truetype");
}

@font-face {
  font-family: "inter900";
  font-weight: 900;
  src: url("assets/Inter-V.ttf") format("truetype");
}

@keyframes counterLow {
  0%,
  100% {
    background: rgba(255, 255, 255, 0.72);
    boxshadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  }

  50% {
    background: rgba(255, 200, 200, 0.72);
    boxshadow: 0px 2px 8px rgba(220, 0, 0, 0.16);
  }
}

@keyframes size-anim {
  0% {
    transform: translate(-110px, 0);
  }

  100% {
    transform: translate(0px, 0);
  }
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"] + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-family: inter400;
  color: #191919;
}

input[type="checkbox"].checkbox-error + label:before {
  border-color: #ea8c7a;
}

input[type="checkbox"] + label:before {
  content: "";
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-top;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  background: rgb(255, 255, 255);
}

input[type="checkbox"]:checked + label:before {
  border: 1px solid #57b231;
  background: #57b231;
}

input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  top: 7px;
  left: 5px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-radius: 1px;
  height: 6px;
  width: 14px;
  transform: rotate(-45deg);
}

input[type="text"],
input[type="password"] {
  width: 100%;
  font-size: 16px;
  padding: 16px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  color: #000000;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  font-family: inter400;
  outline: 0;
}

input[type="text"] + label,
input[type="password"] + label {
  color: #646464;
  font-family: inter400;
  display: block;
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  transform: translate(1rem, -2.45rem);
  transition: all 0.2s ease-out;
}

input[type="text"]:focus + label,
input[type="password"]:focus + label,
input[type="text"]:not(:placeholder-shown) + label,
input[type="password"]:not(:placeholder-shown) + label {
  transform: translate(1.06rem, -3rem);
  font-size: 12px;
}

input:focus {
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

input[type="text"],
input[type="password"] {
  padding-top: 24px;
  padding-bottom: 8px;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: inter400;
  font-size: 16px;
  overflow-y: scroll;
}

td,
th {
  border: none;
  text-align: left;
  height: 64px;
  color: #000;
}

td:nth-child(odd) {
  text-align: center;
}

td:first-of-type div {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  line-height: 32px;
  margin-left: 16px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
  border: 1px solid #e5e5e5;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}
